@import "../../styles/variables/colors";

.line-chart {
  $root: &;
  background: white;
  height: max-content;
  padding: 24px;
  border-radius: 20px;

  .recharts-cartesian-grid-vertical {
    display: none;
  }

  &__header {
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.01em;
    margin-bottom: 32px
  }

  text {
    font-size: 14px;
  }



  &__filter {
    display: flex;
    gap: 2px;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-line;
    position: relative;

    #{$root}__tooltip {
      position: absolute;
      width: 160px;
      height: auto;
      min-height: 60px;
      top: 34px;
      left: 138px;
      padding: 14px 4px 4px;
      border-radius: 8px 8px 24px 24px;
      z-index: 2;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      background: $color-white;
      box-shadow: 0px 4px 24px 0px #00000029;
      transform: scale(0);
      transition: all .3s;

      &--active {
        transform: scale(1);
      }

      &-item {
        padding: 8px 10px;
        cursor: pointer;
        width: 100%;
        color: $color-black;
        transition: all .3s;

        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.01em;

        &:hover {
          opacity: 0.4;
        }
      }
    }
  }

  &__tooltip {
    background: $color-black;
    color: $color-white;
    font-size: 14px;
    padding-top: 0px;
    border-radius: 8px;

    .label {
      padding: 6px 13px;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &-marker {
      .label {
        &::before {
          content: '';
          width: 10px;
          height: 10px;
          display: block;
          background: $color-chart-bar;
          border-radius: 50%;
        }

        &:nth-child(2) {
          &::before {
            background: $color-line-chart;
          }
        }
      }
    }
  }

  .recharts-cartesian-grid-horizontal {
    line:last-child {
      display: none;
    }
  }

  .recharts-wrapper {
    position: relative;

    &::after {
      display: block;
      content: '';
      width: 100%;
      height: 20px;
      position: absolute;
      left: 0;
      top: 0;
      background: $color-white;
    }
  }

  .recharts-xAxis {
    transform: translate(10px, 20px);
  }

  &__period {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .item {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: $color-chart-bar;
    }
  }

  &__correlation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  &__custom-btn {
    padding: 4px 12px !important;

    path {
      transition: all .3s;
    }

    &:hover {
      path {
        stroke: $color-white;
      }
    }
  }
}
