.menu {
  cursor: pointer;
  padding: 10px;
  background: #6698de;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: all .3s;
  width: calc(100% - 20px);

  &__hover {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    backdrop-filter: brightness(60%) blur(5px);
    display: none;
  }

  &__item {
    font-size: 24px;
    list-style-type: none;
  }

  &__item-link {
    text-decoration: none;
    color: #f1faee;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      transition: all .3s;
      opacity: 0.4;
    }
  }

  &__link-wrapper {
    display: flex;
    gap: 20px;
    padding: 0;
  }

  &__img {
    fill: #f1faee;
    height: 32px;
    width: 32px;
    transition: all .3s;
    position: relative;

    &:hover {
      fill: #f1faee7a;
    }
  }


  .logo {
    height: 77px;
    margin-right: 20px;
  }

  &__burger {
    display: none;
    position: absolute;
  }
   


}