.chart-bar {
  background: white;
  height: max-content;
  padding: 24px;
  border-radius: 20px;

  $root: &;

  .recharts-cartesian-grid-horizontal {
    display: none;
  }

  .recharts-cartesian-axis-line {
    display: none;
  }

  .recharts-cartesian-axis-tick-line {
    display: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    border-bottom: #A6A6A6 1px solid;
    padding-bottom: 24px;
    margin-bottom: 16px;
  }

  &__dynamics {
    display: flex;
    flex-direction: column;
    gap: 4px;

    #{$root}__change {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      //TODO вынести
      font-size: 26px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.02em;

    }

    #{$root}__period {

      //TODO вынести
      font-size: 11px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: -0.01em;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-PL {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.01em;

    }

    &-description {

      font-size: 17px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.01em;
    }
  }

  &__description {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: space-between;


    margin-top: 24px;
    padding-top: 24px;
    border-top: #A6A6A6 1px solid;

    span {
      color: #A6A6A6;
      display: flex;
      align-items: center;
      cursor: pointer;
      text-wrap: nowrap;
    }

    .store-info__arrow {
      width: 16px;
      height: 14px;
      path {
        stroke: #A6A6A6;
      }

    }
  }

  &__indicators-month,
  &__forecast-year,
  &__indicators-year {

    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.01em;


    gap: 8px;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      border-radius: 50%;
      width: 10px;
      height: 10px;
      display: block;
    }
  }

  &__forecast-year {
    &::before {
      
    }
  }

  &__indicators-year {
    &::before {
    
    }
  }

  .arrowRegression {
    width: 24px;
    height: 24px;
  }

  &__wrapper {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: flex-end;
  }
}
