.Dashboard {
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  background: #c8c8c8;
  padding: 20px;
  gap: 10px;

  .swiper {
    width: 100%;
  }

  &__line {
    min-width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media only screen and (max-width: 1000px) {
      height: 30vh;
    }
  }

  font-size: 12px;
}