$color-primary:#F4F4F4;
$color-line:#E5E5EF;
$color-primary-light:#E6E6E6;
$color-primary-grey: #A6A6A6;
$color-panel-gray-light:#C2C2C2;
$color-dark:#222324;
$color-black: #000000;
$color-background-panel: #97B1CB;
$color-chart-bar: #194EFF;
$color-done-text: #3056D1;
$color-chart: #364AFF;
$color-shadow-panel: #0D0A2C14;
$color-white: #FFFFFF;
$color-done: #3056d155;
$color-chart-point-light: #AEC1FF;
$color-description:#9291A5;
$color-line-chart:#B469FF;

