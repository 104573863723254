.map {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 34px;
  

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 100px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .leaflet-container {
    outline: 1px solid black;
    height: 70vh;
    width: 45vw;
  }
  .leaflet-control-attribution {
    display: none;
  }
}