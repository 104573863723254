.contacts {
  height: calc(100vh - 154px);
  margin: 0 auto;
  background-color: rgb(239, 239, 239);
  display: flex;
  padding: 34px;
  padding-bottom: 0px;

  &__info {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  a {
    text-decoration: none;
    color: black;
  }

  ul {
    text-decoration: none;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: max-content;
    height: 300px;
  }

  &__bug {


    a {
      color: black;
      text-decoration: none;
      border: 1px solid black;
      padding: 12px 10px;
      border-radius: 14px;
      transition: all .3s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}