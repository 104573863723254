.SummaryData {
  font-size: 20px;
  display: flex;
 align-items: center;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
 
  &__wrpper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    font-size: 20px;
    padding: 20px;
    
  }
  &__line {
    display: flex;
    flex-direction: column;
    gap: 10px;
    span {
      padding: 10px;
      padding-right: 0;
      border-bottom: 1px solid rgb(0, 0, 0);
    }
  }

  &__cell {
    text-align: end;
  }
}