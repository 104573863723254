.home {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #f1faee;
  position: relative;


  &__tetle {
    margin-bottom: 40px;
    font-size: 1.8rem;
    text-align: center;
    transition: all 06s;
    animation-duration: 3s;
    animation-name: title;
    position: relative;
    max-width: 1200px;
    z-index: 2;
    span {
      text-transform: uppercase;
    }
  }

  @keyframes title {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &__slide {
    height: calc(100vh - 101px);
    position: sticky;
    width: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      overflow: hidden;
    }

    &__filter {
      background: rgba(0, 0, 0, 0.312);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      animation: 3s forwards;
      animation-name: filter;
    }

    @keyframes filter {
      from {
        backdrop-filter: blur(0px);
      }

      to {
        backdrop-filter: blur(4px);
      }
    }

    &__background {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: black;
    }

    &__description {
      position: absolute;
      bottom: 10%;
      right: 12%;
      color: rgb(224, 224, 224);
      max-width:  300px;
    }
  }
}